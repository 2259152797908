
/**
 * Site common const and local API variables
 */
// export const apiUrl  = 'https://bm-api-v1.local';
// export const apiKey  = '3e6433bdffbe773b5885cf20ab9fd1b87ed6536ce5e8d6c7692b0e288d7d83c2';
// export const globalVars   = {
//   url: {
//     front:  'http://localhost:8000/',
//     member: 'http://localhost:8080/',
//     back:   'http://localhost:4000/',
//   }
// };


/**
 * Test const
 */
// export const apiUrl = 'https://dev.beaverman.com/api/v0.2';
// export const apiKey = '3e6433bdffbe773b5885cf20ab9fd1b87ed6536ce5e8d6c7692b0e288d7d83c2';
// export const globalVars   = {
//   url: {
//     front:  'https://dev.beaverman.com/',
//     member: 'https://dev.beaverman.com/member/',
//     back:   'https://dev.beaverman.com/admin/',
//   }
// };



/**
 * Production const
 */
export const apiUrl = 'https://admin.beaverman.com/api/v0.2';
export const apiKey = '2c8bd4c69faab93729f50783eef40199f35294f7ac61245dcc621aee4a0a0193';
export const globalVars   = {
  url: {
    front:  'https://www.beaverman.com/',
    member: 'https://www.beaverman.com/member/',
    back:   'https://admin.beaverman.com/',
  }
};