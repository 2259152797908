import Home from '../views/Home.vue'

/**
 * Core Route
 */
 export default [
  // Dashboar
  {
    path: '/',
    name: 'Home',
    component: Home,
  },

  // Log in, Log out
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },

  // Setting
  {
    path: '/setting',
    name: 'Setting',
    component: () => import('../views/Setting.vue'),
  },
]