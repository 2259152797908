<template>
  <div id="app">
    <div v-if="getLoggedSession()" id="duckcms">
      <DuckNav />
      <div id="container">
        <DuckTopbar />

        <div id="body">
          <router-view />
        </div>
      </div>
    </div>
    <div v-if="!getLoggedSession()">
      <router-view/>
    </div>

    <Loader v-if="getShowLoader()" />
  </div>
</template>

<script>
import Loader from '@/components/common/Loader.vue';
import DuckNav from '@/components/layout/DuckNav.vue'
import DuckTopbar from '@/components/layout/DuckTopbar.vue'

export default {
  components: {
    DuckNav,
    DuckTopbar,
    Loader,
  },
  methods: {
    /**
     * Detect user has logged in status
     */
    getLoggedSession() {
      return this.$store.state.logged_in;
    },

    /**
     * Detect this page has show loader object
     */
    getShowLoader() {
      return this.$store.state.show_loader;
    },
  }
}
</script>