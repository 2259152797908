/**
 * Property
 *  config keyword, category, tags or some field for use in this system
 */
 export default [
  {
    path: '/property',
    name: 'PropertyAll',
    component: () => import('../views/property/Property.vue'), // landing of property list
  },
  {
    path: '/property/:slug',
    name: 'PropertyList',
    component: () => import('../views/property/PropertyList.vue'),
  },
  {
    path: '/property/:slug/add',
    name: 'PropertyAdd',
    component: () => import('../views/property/PropertyForm.vue'),
  },
  {
    path: '/property/:slug/edit/:id',
    name: 'PropertyEdit',
    component: () => import('../views/property/PropertyForm.vue'),
  },
 ]