/**
 * Project router
 * 
 * route:
 *  01 - Proect Sectoin
 *    - add 
 *      ๐ step 1
 *      ๐ step 2
 *      ๐ step 3
 *    - View
 *    - Edit
 *      ๐ Edit Main
 *      ๐ Edit Budget and Timeframe
 *      ๐ Edit Location and Readiness
 *      ๐ File upload
 *  
 *  02 - Interest Sectoin
 *    - interest
 *  03 - Auction Sectoin
 *    - Auction
 *    - Auction Request
 *    - Auction Request View
 */
export default [
  {
    path: '/project',
    name: 'Project',
    component: () => import('../views/project/ProjectList.vue'),
  },

  /**
   * Add new project set
   */
  {
    path: '/project/add/step1/:id',
    name: 'Add Project Step 1',
    component: () => import('../views/project/add/ProjectAddStep1.vue'),
  },
  {
    path: '/project/add/step2/:id',
    name: 'Add Project Step 2',
    component: () => import('../views/project/add/ProjectAddStep2.vue'),
  },
  {
    path: '/project/add/step3/:id',
    name: 'Add Project Step 3',
    component: () => import('../views/project/add/ProjectAddStep3.vue'),
  },
  {
    path: '/project/add/step4/:id',
    name: 'Add Project Step 4',
    component: () => import('../views/project/add/ProjectAddStep4.vue'),
  },


  /**
   * View project set
   */
  {
    path: '/project/view/:id',
    name: 'View Project',
    component: () => import('../views/project/ProjectView.vue'),
  },
  
  
  /**
   * Edit project set
   */
  {
    path: '/project/edit/code/:id',
    name: 'ProjectEdit__Code',
    component: () => import('../views/project/edit/ProjectEditCode.vue'),
  },
  {
    path: '/project/edit/general/:id',
    name: 'ProjectEdit__General',
    component: () => import('../views/project/edit/ProjectEditGeneral.vue'),
  },
  {
    path: '/project/edit/budget/:id',
    name: 'ProjectEdit__Budget',
    component: () => import('../views/project/edit/ProjectEditBudget.vue'),
  },
  {
    path: '/project/edit/location/:id',
    name: 'ProjectEdit__Location',
    component: () => import('../views/project/edit/ProjectEditLocation.vue'),
  },
  {
    path: '/project/edit/file/:id',
    name: 'ProjectEdit__File',
    component: () => import('../views/project/edit/ProjectEditFile.vue'),
  },
  
  
  {
    path: '/project/edit/agreement/:id',
    name: 'ProjectEdit__Agreement',
    component: () => import('../views/project/edit/ProjectEditAgreement.vue'),
  },





  /** --------------------------------------------------------------------------------------------
   * prepare agreement
   */
  {
    path: '/project/auction-complete/:id',
    name: 'ProjectEdit__CompleteAuction',
    component: () => import('../views/project/auctionComplete/CompleteAuctionProcess.vue'),
  },





  /** --------------------------------------------------------------------------------------------
   * interest
   */
  {
    path: '/project/find-interest/:id',
    name: 'Find Auctioneer',
    component: () => import('../views/project/SelectAuctioneer.vue'),
  },
  
  


  
  /**--------------------------------------------------------------------------------------------
   * Auction
   */
  {
    path: '/project/auction/:id/:memberId',
    name: 'Auction',
    component: () => import('../views/project/auction/Auction.vue'),
  },
  
  /**
   * Auction Request 
   *  display list about none aprove auction
   */
  {
    path: '/project/auction-request',
    name: 'AuctionRequest',
    component: () => import('../views/project/auction/AuctionRequest.vue'),
  },

  /**
   * Auction Request View
   */
  {
    path: '/project/auction-request/view/:id/:memberId',
    name: 'AuctionRequestView',
    component: () => import('../views/project/auction/AuctionRequestView.vue'),
  },
]