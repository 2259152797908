<template>
  <div id="page-title-bar">
    <div class="topbar">
      <div class="page-title">{{ headerTitle() }}</div>
      <ul class="topbar-nav">
        <li>
          <i class="fa fa-user-circle-o"></i> <a href="#">{{ userFullname() }}</a> 
        </li>
      </ul>
    </div>

    <div v-if="showPageBarComponent()" class="page-bar">
      <component :is="pageBarComponent()"></component>
    </div>
  </div>
</template>

<script>
import RecommendedPageNavComp from '@/components/content/recommended/RecommendedNavComp.vue';

export default {
  props: {
    title: String
  },
  components: {
    RecommendedPageNavComp
  },
  data() {
    return {
      user_id: '',
      user_fullname: ''
    }
  },
  created() {
    this.user_fullname = localStorage.user_fullname;
  },
  methods: {
    /**
     * Return page title
     */
    headerTitle() {
      return this.$store.state.page_title;
    },

    /**
     * return current user full name
     */
    userFullname() {
      return this.$store.state.user.name;
    },



    /**
     * Detect show 'showPageBarComponent'
     */
    showPageBarComponent() {
      return this.pageBarComponent();
    },

    /**
     * Detect this page has assigned 'pageBarComponent'.
     * if assigned, this method will return component name.
     * if not, return false
     */
    pageBarComponent() {
      switch(this.$route.name) {
        case 'PostFormRecommended':
        case 'PostFormRecommendedInfo':
        case 'PostFormRecommendedMedia':
        case 'PostFormRecommendedBanner':
        case 'PostFormRecommendedSEO':
        case 'PostFormRecommendedRating':
          return RecommendedPageNavComp;
        
        default:
          return false;
      }
    }
  }
}
</script>

<style scoped>
#page-title-bar {
  position: relative;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  background: #fff;
  z-index: 100;
}
.topbar {
  position: relative;
}
.page-title {
  padding: 10px 0 10px 30px;
  font: 500 24px/32px Roboto, Kanit;
  color: #000;
}
.topbar-nav {
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  color: #666;
}
.topbar-nav i {
  color: #999;
}
.page-bar {
  padding: 5px 30px;
  border-top: solid 1px #f2f2f2;
}

/**
* 920 pixel or smaller
*/
@media all and (max-width: 1000px) {
  .page-title {
    padding: 10px;
  }
  .topbar-nav, .logout {display: none;}
}

/**
* 720 pixel or smaller
*/
@media all and (max-width: 720px) {
  .page-title {
    padding: 8px 10px;
    font-size: 18px;
    line-height: 24px;
  }
  .topbar-nav {
    display: none;
    /*right: 45px;
    font-size: 13px;*/
  }
  .logout {
    display: none;
  }
}
</style>