/**
 * User router setting
 */
export default [
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/user/UserList.vue'),
  },
  {
    path: '/user/add',
    name: 'User Add',
    component: () => import('../views/user/UserEdit.vue'),
  },
  {
    path: '/user/edit/:id',
    name: 'User Edit',
    component: () => import('../views/user/UserEdit.vue'),
  },
];