/**
 * Posts
 */
 export default [
    {
      path: '/post/:postType',
      name: 'Post',
      component: () => import('../views/post/PostList.vue'),
    },
    
    {
      path: '/post/recommended/edit/:id',
      name: 'PostFormRecommended',
      component: () => import('../views/post/custom/recommended/RecommendedForm.vue'),
    },
        {
          path: '/post/recommended/edit/:id/info',
          name: 'PostFormRecommendedInfo',
          component: () => import('../views/post/custom/recommended/RecommendedFormInfo.vue'),
        },
        {
          path: '/post/recommended/edit/:id/media',
          name: 'PostFormRecommendedMedia',
          component: () => import('../views/post/custom/recommended/RecommendedFormMedia.vue'),
        },
        {
          path: '/post/recommended/edit/:id/banner',
          name: 'PostFormRecommendedBanner',
          component: () => import('../views/post/custom/recommended/RecommendedFormBanner.vue'),
        },
        {
          path: '/post/recommended/edit/:id/seo',
          name: 'PostFormRecommendedSEO',
          component: () => import('../views/post/custom/recommended/RecommendedFormSEO.vue'),
        },
        {
          path: '/post/recommended/edit/:id/rating',
          name: 'PostFormRecommendedRating',
          component: () => import('../views/post/custom/recommended/RecommendedFormRating.vue'),
        },

    {
      path: '/post/homeguru/edit/:id',
      name: 'PostFormHomeGuru',
      component: () => import('../views/post/custom/PostFormHomeguru.vue'),
    },
    {
      path: '/post/testimonial/edit/:id',
      name: 'PostFormTestimonial',
      component: () => import('../views/post/custom/PostFormTestimonial.vue'),
    },
    {
      path: '/post/service-type/edit/:id',
      name: 'PostFormServiceType',
      component: () => import('../views/post/custom/PostFormServiceType.vue'),
    },
    {
      path: '/post/service-feature/edit/:id',
      name: 'PostFormServiceFeature',
      component: () => import('../views/post/custom/PostFormServiceFeature.vue'),
    },
    
    {
      path: '/post/post-banner/edit/:id',
      name: 'PostBannerForm',
      component: () => import('../views/post/post-banner/PostBannerForm.vue'),
    },
    {
      path: '/post/partner-logo/edit/:id',
      name: 'PostPartnerForm',
      component: () => import('../views/post/partner/PartnerForm.vue'),
    },
]