import axios from 'axios';
import {apiUrl, apiKey} from '@/variables/config.js'

/**
 * create Axios instance for send request => GET, POST, PUT, PATCH, DELETE
 */ 
export const apiRequest = axios.create({
  baseURL: apiUrl,
  headers: {
    'Authorization': apiKey,
    'Content-Type': 'application/json'
  },
});

/**
 * create Axios instance for file uploader
 */ 
export const apiFileUpload = axios.create({
  baseURL: apiUrl,
  headers: {
    'Authorization': apiKey,
    'Content-Type': 'multipart/form-data'
  },
});


/**
 * create Axios instance for Robo
 */ 
export const roboApiRequest = axios.create({
  baseURL: 'https://communitybotbyzwizai.herokuapp.com',
  headers: {
    'Authorization':  'd8MG4hLOLb5h97UXUVrYpsNl08CxI013',
    'Content-Type':   'application/json'
  }
});


/**
 * create Axios instance for Kwanjai
 */ 
export const kwanjaiApiRequest = axios.create({
  baseURL: 'https://kwanjai-api.builk.com/api/V2/RequestOrder/CreateRequestOrderSeacon',
  headers: {
    'Authorization':  'aw0e3pr4934tmvd4nmntp345340tr3345jindrththtrjuy34fbgrrombgrfpfgbgdxxthegret4rlrigtmbewioee',
    'Content-Type':   'application/json'
  }
});