import Vue from 'vue'
import Vuex from 'vuex'
import cookie from 'vue-cookies'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    logged_in: false,
    user_session: '',
    user: {},

    show_loader: false,
    page_title: '',
  },
  mutations: {
    /**
     * set user session when refresh
     *  use this function because store will clear when refresh
     *  so we will use cookie save data too.
     */ 
    detectLogged(state) {
      let session_id = cookie.get('user_session');
      
      if(session_id && session_id!=='null') {
        state.logged_in     = true;
        state.user.name     = cookie.get('user_name');
        state.user.email    = cookie.get('user_email');
        state.user.type     = cookie.get('user_type');
        state.user_session  = cookie.get('user_session');
      }
    },

    /**
     * Set user session. use when loading
     * 
     * @param {state} state 
     * @param {*} user 
     */
    setUserSession(state, user) {
      cookie.set('user_session', user.session_id, '7d'); //7d
      cookie.set('user_name'   , user.name,  '7d');
      cookie.set('user_email'  , user.email, '7d');
      cookie.set('user_type'   , user.role,  '7d');

      // initial 'logged in' common value to store
      this.commit('detectLogged');
    },

    /**
     * Logout function. 
     * remove user session from cookie
     * clear user session from store
     * 
     * @param {*} state 
     */
    logout(state) {
      cookie.remove('user_session');
      state.logged_in = false;
      state.user = {}
    },

    /**
     * user for change head title
     * 
     * @param {*} state
     * @param {*} title
     */
    setPageTitle(state, title) {
      state.page_title = title;
    },

    /**
     * set document title in <head> tag
     * @param {*} state 
     * @param {*} status 
     */
    setHeadTitle(state, title) {
      document.title = title + ' | Beaverman Administrator';
    },
    
    /**
     * Set loader
     */
    setLoaderShow(state, status) {
      state.show_loader = status;
    },
  },
})
