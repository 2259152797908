<template>
  <div>
    <div class="dashboard">
      <div class="icon">
        <i class="fa fa-user-circle-o"></i>
      </div>
      <h2>Hi, {{ userFullname() }}</h2>
      <p>Welcome to Beaverman <span style="display: inline-block">Administrator System</span></p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Dashboard',
  page_title: 'Dashboard',
  created() {
    this.$store.commit('setPageTitle', 'Dashboard');
    this.$store.commit('setHeadTitle', 'Dashboard');
  },
  methods: {
    userFullname() {
      return this.$store.state.user.name;
    },
  }
}
</script>


<style scoped>
.dashboard {
  float: none;
  margin: 80px auto 50px auto;
  padding: 50px 0;
  border-radius: 10px;
  max-width: 400px;
  background: #fff;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}
.dashboard i {
  margin-bottom: 20px;
  font-size: 120px;
  line-height: 120px;
  color: #ccc;
}
.dashboard h2 {
  text-transform: uppercase;
  font-weight: 500;;
  font-size: 20px;
  line-height: 24px;
  color: #000;
}
</style>