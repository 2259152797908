/**
 * Import resources
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import cookie from 'vue-cookies'

import DetectPermission from '@/utils/secure/DetectPermission.js';

// import Route
import coreRoute          from '@/router/coreRoute.js';
import contentPageRoute   from '@/router/contentPageRoute.js';
import contentPostRoute   from '@/router/contentPostRoute.js';
import memberRoute        from '@/router/memberRoute.js';
import projectRoute       from '@/router/projectRoute.js';
import termPropertyRoute  from '@/router/termPropertyRoute.js';
import userRoute          from '@/router/userRoute.js';

Vue.use(VueRouter)

// Merge all route to one array
const routes = coreRoute.concat(
                  contentPageRoute,
                  contentPostRoute,
                  memberRoute, 
                  projectRoute,
                  termPropertyRoute,
                  userRoute,
               );

/**
 * initial VueRouter
 */
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/**
 * @function router.beforeEach()
 *    redirect to login page if not logged in and trying to access a restricted page
 */
router.beforeEach((to, from, next) => {
  // list of page that can view without logged in
  const publicPages = [
    '/login'
  ];

  // get value of detection that current page is allowed page
  const authRequired = !publicPages.includes(to.path);

  // get user session
  const user_session = cookie.get('user_session');

  // detect this if it's not authorized page
  if (authRequired && !user_session) {
    // console.log('Redirect to Login page');
    return next('/login');
  }
  // if yes, update session time to 3 minute again
  else if(user_session) {
    cookie.set('user_session', user_session, '7d');

    // detect permission for
    // - user has removed
    // - user has change permission
    DetectPermission();
  }

  

  // view current page
  next(); 
})

export default router
