<template>
  <div>
    <span class="icon">
      <i class="fa" :class="iconClass"></i>
    </span>
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'DuckNavIcon',
  data() {
    return {
      iconClass: 'fa-'
    }
  },
  props: {
    icon: {
      type:String,
      default: ''
    },
    title: {
      type:String,
      default: ''
    }
  },
  created() {
    this.iconClass += this.icon;
  }
}
</script>