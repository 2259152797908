import cookie from 'vue-cookies';
import {apiRequest} from '@/utils/axios/axiosInstance.js';
import {globalVars} from '@/variables/config.js';

const DetectPermission = () => {
  const BASE_URL = globalVars.url.back;
  let userEmail = cookie.get('user_email');
  
  apiRequest
    .get('/user/email', {params: {
      'email': userEmail
    }})
    .then( (res) => {
      if(res.data.status != 200) {
        alert('ชื่อผู้ใช้งานของท่านมีปัญหา กรุณาลงชื่อเข้าใช้งานใหม่ !!!');
        window.location.href = BASE_URL + "login/";
      } else if(res.data.row==0) {
        alert('ผุ้ใช้งานนี้ถูกลบออกจากระบบแล้ว !!!');
        window.location.href = BASE_URL + "login/";
      } else {
        const user = res.data.result;
        
        if(user.role != cookie.get('user_type')) {
          alert('สิทธิ์ของท่านมีการเปลี่ยนแปลง !!!');
          cookie.set('user_type'   , user.role,  '7d');
          window.location.href = BASE_URL;
        }
      }
    });
}
export default DetectPermission;