/**
 * Member router setting
 */
export default [
     {
      path: '/member',
      name: 'Member',
      component: () => import('../views/member/MemberList.vue'),
    },
    {
      path: '/member/select-type',
      name: 'AddMemberSelectType',
      component: () => import('../views/member/MemberAddType.vue'),
    },
    {
      path: '/member/add/:id',
      name: 'AddMember',
      component: () => import('../views/member/MemberEdit.vue'),
    },
    {
      path: '/member/edit/:id',
      name: 'EditMember',
      component: () => import('../views/member/MemberEdit.vue'),
    },
];