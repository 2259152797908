<template>
  <div id="navigator">
    <div class="logo">
      <a href="#" title="DuckCMS">
        <img src="@/assets/img/logo-white.svg" alt="Beaverman">
      </a>
    </div>
    <a href="javascript:void(0);" @click="clickToggle()" class="menu-toggle" :class="{close: navToggle}">
      <div class="inner">
        <span class="line top"></span>
        <span class="line middle"></span>
        <span class="line bottom"></span>
      </div>
    </a>

    <!--
    #Common icon
    fa-plus-square-o
    fa-minus-square-o
    -->
    <nav class="nav" :class="{show: navToggle}">
      <h5 class="title">Auction System</h5>
      <ul class="main-nav">
        <li>
          <router-link to="/" class="level-1st">
            <DuckNavIcon icon="home" title="Dashboard" /> 
          </router-link>
        </li>
        <!-- Dashboard -->
        
        <li v-if="isRole('admin') || isRole('pm') || isRole('qs')" class="has-child" :class="{'expand': toggle.project}">
          <a href="#" @click.prevent="toggleManu('project')" class="level-1st">
            <DuckNavIcon icon="files-o" title="Projects" />
          </a>
          <ul class="subnav">
            <li><router-link to="/project">Project list</router-link></li>
            <li><router-link to="/project/auction-request">Auction Request</router-link></li>
          </ul>
        </li>
        <!-- Project -->

        <li v-if="isRole('admin') || isRole('pm') || isRole('qs')">
          <router-link to="/member" class="level-1st">
            <DuckNavIcon icon="user-circle-o" title="Members" /> 
          </router-link>
        </li>
        <!-- Member -->

        <!-- <li>
          <router-link to="/property" class="level-1st">
            <DuckNavIcon icon="tags" title="Propertie" /> 
          </router-link>
        </li> -->
        <!-- Term -->

        <li v-if="isRole('admin') || isRole('content')" class="has-child" :class="{'expand': toggle.post || toggle.page}">
          <a href="#" @click.prevent="toggleManu('post')" class="level-1st">
            <DuckNavIcon icon="files-o" title="Web Content" /> 
          </a>
          <ul class="subnav">
            <li><router-link to="/post/recommended">Recommended</router-link></li>
            <li><router-link to="/post/homeguru">Home Guru</router-link></li>
            <li><router-link to="/post/testimonial">Testimonials</router-link></li>
            <li><router-link to="/post/service-type">Service Types</router-link></li>
            <li><router-link to="/post/service-feature">Features</router-link></li>
            <li class="separate"></li>
            <li><router-link to="/post/post-banner">Default Post Banner</router-link></li>
            <li><router-link to="/page/promo-popup">Promo Popup</router-link></li>
            <li><router-link to="/post/partner-logo">Partner Logo</router-link></li>
          </ul>
        </li>
        <!-- Website Official Content -->

        <li v-if="isRole('admin')">
          <router-link to="/user" class="level-1st">
            <DuckNavIcon icon="user-circle" title="Users" />
          </router-link>
        </li>
        <!-- User -->

        <li v-if="isRole('admin')">
          <router-link to="/setting" class="level-1st">
            <DuckNavIcon icon="gear" title="Setting" />
          </router-link>
        </li>
        <!-- User -->

        <li class="logout">
          <router-link to="/login" class="level-1st">
            <DuckNavIcon icon="power-off" title="Log out" />
          </router-link>
        </li>
        <!-- User -->

      </ul>
    </nav>
  </div>
  <!-- end: nav -->
</template>

<script>
import DuckNavIcon from '@/components/layout/nav/DuckNavIcon.vue';

export default {
  data() {
    return {
      navToggle: false,
      toggle: {
        project: false,
        post: false,
        page: false,
      },
      user: {}
    }
  },
  components: {
    DuckNavIcon
  },
  created() {
    this.user = this.$store.state.user;
    this.user.type = parseInt(this.user.type);
    
    window.addEventListener('resize', this.closeNav);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.closeNav);
  },
  watch: {
    $route() { //to, from
      let current_path = this.$router.currentRoute.path.split('/', 2)[1];
      this.toggle[current_path] = true;
    }
  },
  methods: {
    /**
     * Detect this user role
     */
    isRole(type) {
      if(type=='admin' && this.user.type==90) {
        return true;
      }else if(type=='pm' && this.user.type==50) {
        return true;
      }else if(type=='qs' && this.user.type==40) {
        return true;
      }else if(type=='content' && this.user.type==10) {
        return true
      }
      return false;
    },

    /**
     * Toggle mobile menu
     */
    clickToggle() {
      this.navToggle = !this.navToggle
      return this.navToggle;
    },

    /**
     * toggle menu method
     *  return status for active submenu
     */
    toggleManu(section) {
      switch(section) {
        case 'project':
          this.toggle.project = !this.toggle.project;
        break;
        case 'post':
          this.toggle.post = !this.toggle.post;
        break;
        case 'page':
          this.toggle.page = !this.toggle.page;
        break;
      }
    }
  }
}
</script>