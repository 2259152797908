/**
 * Import resource
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import VueCookies from 'vue-cookies'
// import titleMixin from './mixins/titleMixin.js'
import moment from 'moment';

/**
 * Load core CSS
 */
import './assets/css/font/awesome/style.css';
// import './assets/css/reset.css';
// import './assets/css/layout.css';
// import './assets/css/form.css';


/**
 * Initial VueJS App
 */
Vue.config.productionTip = false
var numeral = require('numeral');

/**
 * Set Cookie Configuration
 */
Vue.use(VueCookies)
Vue.$cookies.config('7d');
Vue.use(Vuelidate)

/**
 * Initial Mixin
 */
// Vue.mixin(titleMixin);
// Vue.mixin({
//   methods: {
//     objClone:function(obj) {
//       // using native JSON functions removes reactivity
//       // so we can clone an object without mutating the original source
//       return JSON.parse(JSON.stringify(obj));
//     }
//   }
// });

/**
 * All Filter
 */
Vue.filter('nullTo', (val, text) => {
  return (val==='' && typeof val == 'string') ? text : val;
});
Vue.filter("formatNumber", function (value, deximal='') {
  return numeral(value).format("0,0" + deximal); // displaying other groupings/separators is possible, look at the docs
});
Vue.filter('formatDate', function(value) {
  if(value) return moment(String(value)).format('D MMM YYYY / hh:mm');
});
Vue.filter('userRoleTitle', function(value) {
  if(!value) return value;
  switch(value) {
    case '90':
      return 'Administrator';
    case '50':
      return 'Project Manager';
    case '40':
      return 'Quantity Surveyor';
    default: // default is 10
      return 'Content Editor';
  }
});
Vue.filter('memberTypeTitle', function(value) {
  return value==parseInt(20) ? 'ผู้ใช้งาน' : 'ช่าง/ผู้รับเหมา';
});
Vue.filter('constructorTypeTitle', function(value) {
  return value===9 ? 'นิติบุคคล' : 'บุคคลธรรมดา';
});
Vue.filter('projectCatIdToName', function(cat) {
  if(!cat) return '-';
  switch(cat) {
    case '1': return 'ออกแบบ';
    case '2': return 'ก่อสร้าง';
    case '3': return 'ปรับปรุง/ต่อเติม';
    case '4': return 'ซ่อมแซม';
    case '5': return 'ตกแต่งภายใน';
    case '6': return 'งานระบบ';
    case '7': return 'อื่นๆ';
  }
});
Vue.filter('projectBuildingTypeToName', function(type) {
  if(!type) return '-';
  switch(type) {
    case '1': return 'ที่พักอาศัย/บ้าน';
    case '2': return 'อาคาร';
    case '3': return 'สำนักงาน';
    case '4': return 'ร้านค้า';
    case '5': return 'โกดัง/โรงงาน';
    case '6': return 'ห้างสรรพสินค้า';
    case '90': return 'อื่นๆ';
  }
});



new Vue({
  router,
  store,
  beforeCreate() { 
    this.$store.commit('detectLogged');
  },
  render: h => h(App)
}).$mount('#app')
