<template>
  <div class="page-nav">
    <ul>
      <li><router-link :to="setUrl()">Content</router-link></li>
      <li><router-link :to="setUrl('/info')">Info</router-link></li>
      <li><router-link :to="setUrl('/media')">Media</router-link></li>
      <li><router-link :to="setUrl('/banner')">Banner</router-link></li>
      <li><router-link :to="setUrl('/seo')">SEO</router-link></li>
      <li><router-link :to="setUrl('/rating')">Rating</router-link></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'RecommendedNavComp',
  data() {
    return {
      contentId: '',
    }
  },
  created() {
    this.contentId = this.$route.params.id;
  },
  methods: {
    setUrl(path) {
      return '/post/recommended/edit/' + this.contentId + ((path != undefined) ? path : '');
    }
  }
}
</script>

<style scoped>
.page-nav {
  text-align: center;
}
.page-nav li,
.page-nav a {
  display: inline-block;
}
.page-nav a {
  padding: 0 15px;
  color: #000;
}
.page-nav li:first-child a {padding-left: 0;}
.page-nav .router-link-exact-active {
  color: #39c;
}
</style>