/**
 * Page
 */
export default [
    {
      path: '/page/home',
      name: 'PageFormHomepage',
      component: () => import('../views/page/PageFormHomepage.vue'),
    },
    {
      path: '/page/service',
      name: 'PageFormService',
      component: () => import('../views/page/PageFormService.vue'),
    },

    {
      path: '/page/promo-popup',
      name: 'PromoPopupForm',
      component: () => import('../views/post/popup/PromoPopupForm.vue'),
    },
]